import React from "react";

import {
  Grid,
  Segment,
  Label,
  Header,
  List,
  GridColumnProps,
} from "semantic-ui-react";

export const ListingCardPlaceholder: React.FC<{
  loading?: boolean;
  columnProps?: GridColumnProps;
}> = (props) => {
  return (
    <Grid.Column className="listing-card-container" {...props.columnProps}>
      <Segment
        className="listing-card"
        raised
        style={{
          backgroundColor: "#eee",
        }}
      >
        <Label as="div" size="large" basic ribbon>
          <div
            className={`content-placeholder ${
              props.loading ? "loading" : null
            }`}
            style={{
              width: 50,
              lineHeight: 1,
              height: "100%",
              borderRadius: "20px",
            }}
          >
            &nbsp;
          </div>
        </Label>
      </Segment>
      <div className="listing-card-footer">
        <Header as="h5" style={{ color: "white", marginBottom: 0 }}>
          <div
            className={`content-placeholder ${
              props.loading ? "loading" : null
            }`}
            style={{
              width: 200,
              lineHeight: 1,
              height: 20,
              borderRadius: "20px",
            }}
          >
            &nbsp;
          </div>
        </Header>
        <div
          className={`content-placeholder ${props.loading ? "loading" : null}`}
          style={{
            width: 35,
            lineHeight: 1,
            height: 35,
            borderRadius: "50%",
            float: "right",
            marginTop: "-15px",
          }}
        >
          &nbsp;
        </div>
        <List horizontal size="small" style={{ marginTop: 0, marginBottom: 0 }}>
          <List.Item>
            <div
              className={`content-placeholder ${
                props.loading ? "loading" : null
              }`}
              style={{
                width: 40,
                lineHeight: 1,
                height: 18,
                borderRadius: "20px",
              }}
            >
              &nbsp;
            </div>
          </List.Item>
          <List.Item>
            <div
              className={`content-placeholder ${
                props.loading ? "loading" : null
              }`}
              style={{
                width: 40,
                lineHeight: 1,
                height: 18,
                borderRadius: "20px",
              }}
            >
              &nbsp;
            </div>
          </List.Item>
          <List.Item>
            <div
              className={`content-placeholder ${
                props.loading ? "loading" : null
              }`}
              style={{
                width: 40,
                lineHeight: 1,
                height: 18,
                borderRadius: "20px",
              }}
            >
              &nbsp;
            </div>
          </List.Item>
        </List>
        <br />
        <List horizontal size="small" style={{ marginTop: 0, marginBottom: 0 }}>
          <List.Item>
            <div
              className={`content-placeholder ${
                props.loading ? "loading" : null
              }`}
              style={{
                width: 15,
                lineHeight: 1,
                height: 15,
                borderRadius: "20px",
              }}
            >
              &nbsp;
            </div>
          </List.Item>
          <List.Item>
            <div
              className={`content-placeholder ${
                props.loading ? "loading" : null
              }`}
              style={{
                width: 60,
                lineHeight: 1,
                height: 15,
                borderRadius: "20px",
              }}
            >
              &nbsp;
            </div>
          </List.Item>
        </List>
        <br />
        <List horizontal size="small" style={{ marginTop: 0, marginBottom: 0 }}>
          <List.Item>
            <div
              className={`content-placeholder ${
                props.loading ? "loading" : null
              }`}
              style={{
                width: 15,
                lineHeight: 1,
                height: 15,
                borderRadius: "20px",
              }}
            >
              &nbsp;
            </div>
          </List.Item>
          <List.Item>
            <div
              className={`content-placeholder ${
                props.loading ? "loading" : null
              }`}
              style={{
                width: 50,
                lineHeight: 1,
                height: 15,
                borderRadius: "20px",
              }}
            >
              &nbsp;
            </div>
          </List.Item>
        </List>
      </div>
    </Grid.Column>
  );
};

export default ListingCardPlaceholder;
