import React from "react";
import { Label, LabelProps } from "semantic-ui-react";

type Availability = "INSTANT" | "AVAILABLE" | "LIKELY" | "MAYBE" | "EXPIRED";
interface AvailabilityLabelProps extends LabelProps {
  availability: Availability;
}

const getAvailabilityProps = (props: AvailabilityLabelProps): LabelProps => {
  const nextProps = {
    className: "likelihood-label",
    style: props.style || {},
    size: props.size || "medium",
  };

  switch (props.availability) {
    case "INSTANT":
      return {
        color: "green",
        content: "Available",
        icon: "check",
      };
    case "AVAILABLE":
      return {
        color: "green",
        content: "Available",
        icon: "check",
      };
    case "LIKELY":
      return {
        color: "teal",
        content: "Likely Available",
        ...nextProps,
      };
    case "MAYBE":
      return {
        color: "orange",
        content: "Maybe Available",
        ...nextProps,
      };
    case "EXPIRED":
    default:
      return { content: "Expired", ...nextProps };
  }
};

export const AvailabilityLabel: React.FC<AvailabilityLabelProps> = (props) => {
  const nextProps = getAvailabilityProps(props);

  return <Label {...nextProps} />;
};
export default AvailabilityLabel;
