import React, { useState, useEffect } from "react";
import ListingCardPlaceholder from "./ListingCardPlaceholder";
import { IListingCardProps } from "./ListingCardTypes";
import ListingCardDetail from "./ListingCardDetail";

const ListingCard: React.FC<IListingCardProps> = (props) => {
  const {
    showPrice = true,
    showLike = true,
    showMlsId = false,
    showAvailability = true,
    showDateListed = true,
    listing,
    style,
    loading,
    placeholder,
    columnProps,
    noHref,
    size,
    as,

    //actions
    onClick,
    unlikeListing,
    likeListing,
    onMouseEnterListing,
    onMouseLeaveListing,
  } = props;

  const [isLiked, setIsLiked] = useState(false);

  const hasListingId = listing?.listingId;

  useEffect(() => {
    if (listing && listing.liked) {
      setIsLiked(listing.liked);
    }
  }, [listing, hasListingId]);

  const handleLike = () => {
    const listingId = listing?.listingId;
    if (!listingId) return;

    if (isLiked) {
      const result = unlikeListing?.(listingId);
      if (result) {
        setIsLiked(false);
      }
      return;
    }
    const result = likeListing?.(listingId);
    if (result) {
      setIsLiked(true);
    }
    return;
  };

  const handleMouseEnter = (listingId: number) => {
    if (onMouseEnterListing) {
      return onMouseEnterListing(listingId);
    }
    return () => false;
  };

  const handleMouseLeave = (listingId: number) => {
    if (onMouseLeaveListing) {
      return onMouseLeaveListing(listingId);
    }
    return () => false;
  };

  if (loading) {
    return <ListingCardPlaceholder loading />;
  }

  if (placeholder) {
    return <ListingCardPlaceholder />;
  }

  const detailProps = {
    showPrice,
    showLike,
    showMlsId,
    showAvailability,
    showDateListed,
    style,
    listing,
    isLiked,
    columnProps,
    noHref,
    onClick,
    size,
    as,
  };

  return (
    <ListingCardDetail
      // @ts-ignore
      listing={listing}
      handleLike={handleLike}
      onMouseEnterListing={handleMouseEnter}
      onMouseLeaveListing={handleMouseLeave}
      {...detailProps}
    />
  );
};

export default ListingCard;
export { ListingCard };
