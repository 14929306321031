export const ImageSize = {
  Large: "1040/780",
  Medium: "800/600",
  Small: "400/300",
  Thumbnail: "80/80",
} as const;

type ImageSizeValue = typeof ImageSize[keyof typeof ImageSize];

export const imageSizeToObject = (size: ImageSizeValue) => {
  const [width, height] = size.split("/");
  return {
    width,
    height,
  };
};

interface CreateImageOptions {
  size: ImageSizeValue;
  srcSet?: boolean;
  skipImgServer?: boolean;
}

const urlSafeBase64 = (string: string) => {
  return Buffer.from(string)
    .toString("base64")
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

export const createImageSrc = (url: string, _opts?: CreateImageOptions) => {
  if (url.includes("http")) return url;
  return process.env.NEXT_PUBLIC_CDN_HOST + "/" + url;
};

export const createImageSrcSet = (url: string, skipImgServer?: boolean) => {
  return Object.values(ImageSize)
    .map((v) => {
      const src = createImageSrc(url, { size: v, skipImgServer });
      const width = v.split("/")[0] + "w";

      return `${src} ${width}`;
    })
    .join(",");
};
