import React from "react";
// @ts-ignore
import Swipeable from "react-swipeable";
import { Button, Progress } from "semantic-ui-react";
import Link from "next/link";
import { IImageCarouselProps, IIMageCarouselState } from "./ImageCarouselTypes";

const RIGHT = "-1" as const;
const LEFT = "+1" as const;

type Direction = typeof RIGHT | typeof LEFT;
export default class ImageCarousel extends React.Component<
  IImageCarouselProps,
  IIMageCarouselState
> {
  state = {
    imageIdx: 0,
    deltaX: 0,
  };

  static defaultProps = {
    progress: false,
  };

  shouldComponentUpdate(_: any, nextState: IIMageCarouselState) {
    return this.state.imageIdx !== nextState.imageIdx;
  }

  createOnSwiped = (direction: Direction) => {
    return (e: TouchEvent) => {
      if (e.cancelable) {
        e.preventDefault();
      }
      this.handleChange(direction);
    };
  };

  handleChange = (direction: Direction) => {
    const { images } = this.props;
    const change = direction === RIGHT ? RIGHT : LEFT;
    const adjustedIdx = this.state.imageIdx + Number(change);
    let newIdx;
    if (adjustedIdx >= images.length) {
      newIdx = 0;
    } else if (adjustedIdx < 0) {
      newIdx = images.length - 1;
    } else {
      newIdx = adjustedIdx;
    }
    this.setState({ imageIdx: newIdx, deltaX: 0 });
  };

  render() {
    const {
      images,
      linkTo,
      className,
      progress,
      linkToProps,
      noHref,
      onClick,
    } = this.props;
    const { imageIdx = 0 } = this.state;
    const img = images[imageIdx];

    const shouldShowControl = images.length > 1;

    return (
      <React.Fragment>
        <Swipeable
          trackMouse
          preventDefaultTouchmoveEvent
          onSwipedLeft={this.createOnSwiped(LEFT)}
          onSwipedRight={this.createOnSwiped(RIGHT)}
          className={className}
        >
          <picture>
            <img
              className={className}
              style={{
                background:
                  img.meta.hasOwnProperty("colors") &&
                  Array.isArray(img.meta.colors)
                    ? img.meta.colors[0]
                    : "#eee",
              }}
              alt=""
            />
            <img
              data-src={img.src}
              className={className + " lazyload"}
              src={imageIdx > 0 ? img.src : ""}
              alt=""
            />
          </picture>

          <div className="carousel-controls">
            {noHref && <span className="carousel-link" onClick={onClick} />}

            {!noHref && (
              <Link href={linkTo} {...linkToProps} passHref>
                {/* eslint-disable-next-line  */}
                <a className="carousel-link" target="_blank" />
              </Link>
            )}
            {shouldShowControl && (
              <React.Fragment>
                <Button
                  className="prev-btn"
                  basic
                  icon="chevron left"
                  inverted
                  onClick={() => this.handleChange(RIGHT)}
                  id="app::photo-prev"
                  data-ga-event
                />
                <Button
                  className="next-btn"
                  basic
                  icon="chevron right"
                  inverted
                  onClick={() => this.handleChange(LEFT)}
                  id="app::photo-next"
                  data-ga-event
                />
              </React.Fragment>
            )}
          </div>
        </Swipeable>
        {progress && (
          <Progress
            total={images.length}
            attached="bottom"
            value={imageIdx + 1}
            // color="white"
            className="carousel-progress"
          />
        )}
      </React.Fragment>
    );
  }
}
