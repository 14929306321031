import React from "react";

interface BurstEnhancementProps {
  enabled: boolean;
}
export default class BurstEnhancement extends React.PureComponent<
  BurstEnhancementProps
> {
  state = {
    enabled: this.props.enabled,
  };

  ref: any;
  mojs: any;
  timeline: any;
  animations: any;

  constructor(props: BurstEnhancementProps) {
    super(props);
    if (process.browser) {
      const RADIUS = 50;
      // @ts-ignore
      import("mo-js").then((mod) => {
        this.mojs = mod.default;

        const burst = new this.mojs.Burst({
          left: 0,
          top: 0,
          radius: { 6: RADIUS - 7 },
          angle: 45,
          isShowStart: true,
          isShowEnd: false,
          children: {
            shape: "line",
            radius: RADIUS / 7.3,
            scale: 1,
            stroke: "#FD7932",
            strokeDasharray: "100%",
            strokeDashoffset: { "-100%": "100%" },
            degreeShift: "stagger(0,-5)",
            duration: 700,
            delay: 200,
            easing: "quad.out",
          },
          onStart() {
            this.el.style.zIndex = `9999`;
          },
          onComplete() {
            this.el.style.zIndex = "-1";
          },
        });

        this.animations = [burst];

        this.timeline = new this.mojs.Timeline({ speed: 1.5 });

        this.timeline.add(burst);
      });
    }
  }

  static getDerivedStateFromProps(nextProps: BurstEnhancementProps) {
    return {
      enabled: nextProps.enabled,
    };
  }

  animate = (child: React.ReactElement) => {
    return (...args: any[]) => {
      if (child.props.onClick) {
        child.props.onClick(...args);
      }

      if (this.state.enabled && this.ref) {
        const bodyRect = document.body.getBoundingClientRect();
        const elRect = this.ref.getBoundingClientRect();
        this.animations.forEach((animation: any) => {
          animation.tune({
            x: elRect.x + elRect.width / 2,
            y: elRect.top + elRect.height / 2 - bodyRect.top,
          });
        });
        this.timeline.replay();
      }
    };
  };

  render() {
    return React.Children.map(this.props.children, (child) => {
      if (!child) return null;
      if (!React.isValidElement(child)) return child;

      return React.cloneElement(
        child,
        {
          onClick: this.animate(child),
          style: {
            cursor: `pointer`,
            userSelect: `none`,
          },
        },
        [
          <span
            key="0"
            ref={(ref) => (this.ref = ref)}
            className="burst-inner"
            children={child.props.children}
          />,
        ]
      );
    });
  }
}
