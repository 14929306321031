import React, { useMemo } from "react";
import { IListingCardDetailProps } from "./ListingCardTypes";
import Link from "next/link";
import {
  Icon,
  Segment,
  Label,
  Header,
  List,
  GridColumn,
} from "semantic-ui-react";
import numeral from "numeral";
import AvailabilityLabel from "../AvailabilityLabel";
import ImageCarousel, { ICarouselImage } from "../ImageCarousel";
import BurstEnhancement from "../BurstEnhancement";
import format from "date-fns/format";
import { formatDistance } from "date-fns";
import { RiHeart2Line } from "react-icons/ri";
import { BiBed, BiBath, BiArea, BiBox } from "react-icons/bi";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useAccountStore } from "../../stores/AccountStore";
import { createImageSrc, ImageSize } from "../../util/img";

const IconLabelWrapper: React.FC = ({ children }) => {
  return (
    <span
      style={{
        display: "flex",
        lineHeight: "1.5em",
      }}
    >
      {children}
    </span>
  );
};

const LinkWrapper: React.FC<{
  href: string;
  noHref?: boolean;
  onClick: () => void;
}> = ({ href, children, noHref, onClick }) => {
  if (noHref) {
    return <span>{children}</span>;
  }
  return (
    <Link href={href} passHref>
      {/* eslint-disable-next-line */}
      <a target="_blank" onClick={onClick}>
        {children}
      </a>
    </Link>
  );
};

export const ListingCardDetail: React.FC<IListingCardDetailProps> = observer(
  function ListingCardDetail(props) {
    const account = useAccountStore();
    const isLoggedIn = account.isLoggedIn;
    const {
      showPrice,
      showLike,
      showMlsId,
      showAvailability,
      showDateListed,
      style,
      isLiked,
      listing,
      columnProps,
      handleLike,
      onMouseEnterListing,
      onMouseLeaveListing,
      onClick,
      noHref,
      size,
      as: Element = GridColumn,
    } = props;

    const {
      listingId,
      listPrice,
      streetNumber,
      route,
      bed,
      den,
      bath,
      squareFoot,
      unitNumber,
      listingImages,
      mlsid,
      moveInDateParsed,
      linkTo,
      listingCreatedAt,
      availability,
      instabook,
      // listingType,
      saleType,
      expiryDate,
      soldDate,
      soldPrice,
    } = listing;

    const isLease = saleType === "LEASE";

    const images: ICarouselImage[] = useMemo(
      () =>
        listingImages.map((img) => {
          const url = img.baseUrl + img.fileKey;
          return {
            src: createImageSrc(url, { size: ImageSize.Small }),
            url,
            hq: url,
            meta: img.meta || {
              colors: [],
            },
            key: img.id,
          };
        }),
      [listingImages]
    );

    const lineThrough: React.CSSProperties = expiryDate
      ? {
          textDecoration: "line-through",
          color: "rgba(0,0,0,0.6)",
        }
      : {};

    return (
      <Element
        {...columnProps}
        className="listing-card-container"
        style={{ ...style }}
        onMouseEnter={() => onMouseEnterListing?.(listingId)}
        onMouseLeave={() => onMouseLeaveListing?.(listingId)}
      >
        <Segment className={clsx("listing-card", size)} raised>
          <div className="listing-card-bg" style={{ background: "#eee" }} />

          <ImageCarousel
            images={images}
            linkTo={linkTo}
            className="listing-card-bg"
            progress
            noHref={noHref}
            onClick={() => onClick?.(listing)}
          />

          {showPrice && (
            <Label as="div" size="large" basic ribbon>
              <span style={lineThrough}>
                {numeral(listPrice).format("$0,0")}
              </span>
            </Label>
          )}

          {isLoggedIn && soldPrice && saleType === "SALE" && (
            <>
              <br />
              <Label as="div" basic size="large" ribbon>
                {numeral(soldPrice).format("$0,0")}
              </Label>
            </>
          )}
          <br />

          {isLoggedIn && expiryDate && (
            <Label as="div" size="small" basic ribbon>
              {soldDate ? (saleType === "SALE" ? "Sold" : "Leased") : "Expired"}
            </Label>
          )}

          <br />
          {instabook && (
            <Label
              as="div"
              size="small"
              color="blue"
              content="Instabook"
              icon="lightning"
              ribbon
            />
          )}
        </Segment>

        <div className="listing-card-footer">
          {showLike && (
            <BurstEnhancement enabled={!isLiked}>
              <Icon
                className={clsx("heart-icon", isLiked && "liked-heart-icon")}
                size="big"
                style={{ zIndex: 3 }}
                onClick={handleLike}
              >
                <RiHeart2Line />
              </Icon>
            </BurstEnhancement>
          )}

          <LinkWrapper
            href={linkTo}
            noHref={noHref}
            onClick={() => onClick?.(listing)}
          >
            <Header as="h3" style={{ marginBottom: 0, marginTop: 0 }}>
              {showMlsId && `MLSID: ${mlsid} - `}
              {streetNumber + " " + route}
              {unitNumber && `, Unit ${unitNumber}`}
            </Header>

            <List
              horizontal
              size="tiny"
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <List.Item>
                <IconLabelWrapper>
                  <BiBed name="bed" fontSize="1.5em" />{" "}
                  {bed === 0 ? "Studio" : bed} {den > 0 ? "+" : null}
                </IconLabelWrapper>
              </List.Item>
              <List.Item>
                <IconLabelWrapper>
                  <BiBath name="tint" fontSize="1.5em" /> {bath}
                </IconLabelWrapper>
              </List.Item>
              <List.Item>
                <IconLabelWrapper>
                  <BiArea name="th-large" fontSize="1.5em" />{" "}
                  {Array.isArray(squareFoot) && squareFoot.length === 2
                    ? squareFoot.join("-")
                    : "N/A"}
                </IconLabelWrapper>
              </List.Item>
              <br />

              {isLease && (
                <React.Fragment>
                  <List.Item style={{ margin: "0 0 0 2px" }}>
                    <small>
                      <IconLabelWrapper>
                        <BiBox name="truck" fontSize="1.5em" /> Move In:{" "}
                        {moveInDateParsed === null
                          ? "Inquire"
                          : format(new Date(moveInDateParsed), "PP")}
                      </IconLabelWrapper>
                    </small>
                  </List.Item>
                  <br />
                </React.Fragment>
              )}

              <List.Item style={{ margin: "0 0 0 2px" }}>
                {isLease && showAvailability && !soldDate && (
                  <AvailabilityLabel availability={availability} />
                )}{" "}
                {showDateListed && (
                  <small>
                    Listed{" "}
                    {formatDistance(new Date(listingCreatedAt), new Date(), {
                      addSuffix: true,
                    })}
                  </small>
                )}
                {soldDate && soldPrice && (
                  <>
                    <br />
                    <small>
                      Sold{" "}
                      {formatDistance(new Date(soldDate), new Date(), {
                        addSuffix: true,
                      })}
                    </small>
                  </>
                )}
              </List.Item>
            </List>
          </LinkWrapper>
        </div>
      </Element>
    );
  }
);
export default ListingCardDetail;
